import React from "react";
import PropTypes from "prop-types";
import Showdown from "showdown";
import { Container, Col } from "react-bootstrap";

const TextSection = ({ data }) => {
  const convertMarkDown = new Showdown.Converter();
  const returnHTMLData = () => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: convertMarkDown.makeHtml(data.text),
        }}
      />
    );
  };
  return (
    <Container>
       <Col xs={12}>
      <h2 className={"text-center section-title"}>{data.title}</h2>
      {returnHTMLData()}
      </Col>
    </Container>
  );
};

TextSection.propTypes = {
  data: PropTypes.object,
};

export default TextSection;

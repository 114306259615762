import React, { Fragment } from "react";
import GoogleMapReact from "google-map-react";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

const SiteFooterMap = ({ menuItems, displayMap = true }) => {
  const defaultProps = {
    center: {
      lat: 63.41154192143531,
      lng: 10.402321439357097,
    },
    zoom: 15,
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
      position: { lat: 63.41154192143531, lng: 10.402321439357097 },
      map,
      title: "Scandic Lerkendal - Conference Venue",
    });
    return marker;
  };

  return (
    <div>
      {displayMap && (
        <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCR_REOa_3Kg0ZH-BYH7GrGBbhyg7EfDI0" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        ></GoogleMapReact>
        </div>
      )}
      <Container>
        <footer className="page-footer footer">
          <hr className="featurette-divider" />
          <div className="text-center">
            <Row>
              {menuItems.map(({ menuItem }, i) => {
                const menu = menuItem.split(" ").join("");
                return (
                  <Fragment key={i}>
                    <Col xs={6} md={4}>
                      <a
                        className={"footerLinks"}
                        href={`#${menu.split(" ").join("")}`}
                      >
                        {menuItem}
                      </a>
                    </Col>
                  </Fragment>
                );
              })}
              <Container>
                <Col xs={12}>
                  <p style={{ textAlign: "center" }}>Credits to ECPPM Russia</p>
                  <p style={{ textAlign: "center" }}>
                    Developed and managed by Qualtrica
                  </p>
                </Col>
              </Container>
            </Row>
          </div>
        </footer>
      </Container>
    </div>
  );
};
SiteFooterMap.propTypes = {
  menuItems: PropTypes.array,
  displayMap: PropTypes.bool
};
export default SiteFooterMap;
